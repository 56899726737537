import styles from './Jalneti.module.scss';
import svg2 from '../../../assets/svS_1a.svg';
import img from '../../../assets/jalaneti_830x300.jpg';
import Acco from './Accordion';
import { Button } from 'react-bootstrap';

const Jalneti = () => {
    return(
        <div className={styles.title}>
            <h1>Jalneti</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <div className={styles.left}>
                    <img src={img} alt='jalneti' width='100%'></img>
                    <p>Jala Neti is an easy yet powerful practice that clears the nasal passages with salt water, using a copper Jala Neti pot designed by Sadhguru. This cleansing process removes mucus and impurities, allowing air to flow freely into the lungs for easier, unimpeded breathing throughout the day.</p>
                    <p><b>Benefits:</b><br/>
                    ♦ Helps with the diseases of eyes, nose, throat.<br/>
                    ♦ Removes excess mucus and pollutants from nasal passages and sinuses.<br/>
                    ♦ Helps relieve insomnia, tirednes, improves quality of sleep.<br/>
                    ♦ Prevents and helps with respiratory tract diseases like asthma, pneumonia, bronchitis.<br/>
                    ♦ Relieves cold, allergies, sinusitis.<br/>
                    ♦ Helps with tonsillitis, allergic rhinitis, high fever.<br/>
                    ♦ Relieve migrane and headaches, anxiety, stress release and brings tranquility.<br/>
                    ♦ Helps with epilepsy.<br/>
                    </p>
                    <Acco />
                </div>
                <div className={styles.right}>
                    <h2>upcoming offerings</h2>
                    <img src={svg2} alt='svC' width='275px' style={{paddingBottom:'50px'}}></img>
                    <div className={styles.card}>
                        <h5>Jalneti <hr/></h5>
                        <p><b>Location: </b>Sayuri Hospital, behind DryFruit House, Postal Colony, Shankar Kalat Nagar, Wakad, Pune, Pimpri-Chinchwad, Maharashtra - 411057</p>
                        <p><b>Date: </b>-</p>
                        <p><b>Time: </b>-</p>
                        <Button disabled>Register Now</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Jalneti;