import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
// import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import styles from './Navbar.module.scss';

const NavbarH = () => {
  const navigate = useNavigate();

  const navigateToHomeAndScroll = (section) => {
    navigate("/"); // Navigate to home
    setTimeout(() => {
      document.getElementById(section)?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);

  const handleNavClick = () => {
    setExpanded(false); // Collapse the menu when a link is clicked
  };

  return (
    <Navbar expand="lg" className={`bg-body-tertiary ${styles.navbar}`} expanded={expanded}>
      <Container className={styles.wrapper}>
        {/* Logo with navigation to homepage */}
        <Navbar.Brand onClick={() => {navigateToHomeAndScroll("home"); handleNavClick();}}>
          <img src={logo} alt="Logo" />
        </Navbar.Brand>

        {/* Navbar toggle for mobile view */}
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}/>

        {/* Navbar content */}
        <Navbar.Collapse id="basic-navbar-nav" className={`flex justify-content-end ${styles.collapse}`}>
          <Nav className={`${styles.tabs}`}>
            {/* Links to different sections */}

            <Nav.Link onClick={() => {navigateToHomeAndScroll("home"); handleNavClick();}}>
              Home
            </Nav.Link>

            {/* Dropdown menu */}
            <NavDropdown title="Offerings" id="basic-nav-dropdown">
              <NavDropdown.Item href="/angamardana">Angamardana</NavDropdown.Item>
              <NavDropdown.Item href="/bhuta-shuddhi">Bhuta Shuddhi</NavDropdown.Item>
              {/* <NavDropdown.Divider /> */}
              <NavDropdown.Item href="/jal-neti">Jal Neti</NavDropdown.Item>
              <NavDropdown.Item href="/pregnancy-practices">Pregnancy Practices</NavDropdown.Item>
              <NavDropdown.Item href="/shanmukhi-mudra">Shanmukhi Mudra</NavDropdown.Item>
              <NavDropdown.Item href="/surya-kriya">Surya Kriya</NavDropdown.Item>
              <NavDropdown.Item href="/surya-shakti">Surya Shakti</NavDropdown.Item>
              <NavDropdown.Item href="/yogasanas">Yogasanas</NavDropdown.Item>
            </NavDropdown>

            <Nav.Link onClick={() => {navigateToHomeAndScroll("up-offerings"); handleNavClick();}}>
              Upcoming Offerings
            </Nav.Link>

            <Nav.Link onClick={() => {navigateToHomeAndScroll("about"); handleNavClick();}}>
              About
            </Nav.Link>

            <Nav.Link onClick={() => {navigateToHomeAndScroll("contact"); handleNavClick();}}>
              Contact
            </Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarH;
