import styles from './Angamardana.module.scss';
import svg2 from '../../../assets/svS_1a.svg';
import img from '../../../assets/Angamardhana_830x300.jpg';
import Acco from './Accordion';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Angamardana = () => {
    return(
        <div className={styles.title}>
            <h1>Angamardana</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <div className={styles.left}>
                    <img src={img} alt='angamardana' width='100%'></img>
                    <p>Angamardana, a fitness system rooted in yoga, offers everyone the opportunity to invigorate the body and reach peak physical and mental health. “Angamardana” means gaining complete mastery over the limbs, organs, and other parts of the body. True to its name, this practice revitalizes the body on all levels including the muscles, circulatory system, skeletal structure, nervous system, and the basic energy system.</p>
                    <p><b>Benefits:</b><br/>
                    ♦ Strengthens the spine, skeletal and muscular systems.<br/>
                    ♦ Builds physical strength, fitness and tenacity.<br/>
                    ♦ Invigorates the body, bringing a sense of lightness and freedom in the body.<br/>
                    ♦ Prepares the body for Hatha Yoga.<br/>
                    ♦ Revitalizes the body including the muscles, blood circulation, skeletal and nervous systems.<br/>
                    ♦ Helps in weight-loss.<br/>
                    </p>
                    <Acco />
                </div>
                <div className={styles.right}>
                    <h2>upcoming offerings</h2>
                    <img src={svg2} alt='svC' width='275px' style={{paddingBottom:'50px'}}></img>
                    <div className={styles.card}>
                        <h5>Angamardana <hr/></h5>
                        <p><b>Location: </b>Forest Valley, Kharadi</p>
                        <p><b>Date: </b>23rd - 26th Nov 2024</p>
                        <p><b>Time: </b>06:30 AM - 09:15 AM</p>
                        <Link to={"/angamardana/registration"}>
                            <Button>Register Now</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Angamardana;