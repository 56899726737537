import styles from './Pregnancy.module.scss';
import svg2 from '../../../assets/svS_1a.svg';
import img from '../../../assets/pregnancy_830x300.jpg';
import Acco from './Accordion';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Pregnancy = () => {
    return(
        <div className={styles.title}>
            <h1>Pregnancy Practices</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <div className={styles.left}>
                    <img src={img} alt='pregnancy' width='100%'></img>
                    <p>This is a program dedicated to supporting the well-being of pregnant women and their unborn children. Recognizing the profound impact a mother’s physical, mental, and emotional state has on her child’s development, ancient Indian culture developed a comprehensive approach to maternal care. Inspired by this wisdom, Isha Thaimai helps expectant mothers cultivate awareness and maintain a pleasant body, mind, and emotions. The program also offers guidance for a healthy delivery and postnatal care, empowering mothers in nurturing their newborns.</p>
                    <p><b>Benefits:</b><br/>
                    ♦ Strengthens the pectoral muscles to avoid pain in the shoulders, shoulder blades and to avoid spinal problems.<br/>
                    ♦ Activates and stretches the spine, which benefits the neurological development of the child.<br/>
                    ♦ Helps to prepare the body for natural birth and make delivery easier.<br/>
                    </p>
                    <Acco />
                </div>
                <div className={styles.right}>
                    <h2>upcoming offerings</h2>
                    <img src={svg2} alt='svC' width='275px' style={{paddingBottom:'50px'}}></img>
                    <div className={styles.card}>
                        <h5>Yoga Workshop for Pregnant Women<hr/></h5>
                        <p><b>Location: </b></p>
                        <p><b>Date: </b>-</p>
                        <p><b>Time: </b>-</p>
                        <Link to={"/pregnancy-practices-form"}>
                            <Button>Show interest</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pregnancy;