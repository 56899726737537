import styles from './pregnancyForm.module.scss';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import React, { useRef, useState } from "react";

const PregnancyForm = () => {
  const formRef = useRef(null);
//   const puneAddress = "Sayuri Hospital, behind DryFruit House, Postal Colony, Shankar Kalat Nagar, Wakad, Pune"
//   const [currentCity, setCity] = useState("")
//   const [currentLocation, setCurrentLocation] = useState("")
  const [selectedAilments, setSelectedAilments] = useState([]);
  const handleCityChange = (e) => {
    const { value} = e.target;
    //setCity(value)
  }
  const handleLocationChange = (e) => {
    const { value} = e.target;
    //setCurrentLocation(value)
  }
  const handleAilmentsChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      // Add value to the state if checkbox is checked
      setSelectedAilments((prev) => [...prev, value]);
    } else {
      // Remove value from the state if checkbox is unchecked
      setSelectedAilments((prev) => prev.filter((item) => item !== value));
    }
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    
    const testFormData = new FormData(formRef.current);
    testFormData.append("Ailments", selectedAilments.join(', '));
    testFormData.append("ProgramName", "Pregnancy Practice");
    fetch(process.env.REACT_APP_PREGNANCY_GOOGLE_SCRIPT_URL, {
      method: 'POST',
      body: testFormData,
    }).then(res => res.json())
      .then(data => {
        console.log(data);
        formRef.current.reset();
      })
      .catch(err => console.log(err));
  };

  return (
    <div className={`py-5`}>
    <Container className={styles.container}>
      <Form className={styles.form} ref={formRef} onSubmit={handleSubmit}>
        
        {/* <h3>Contact Form</h3> */}

        <Form.Group className="mb-3" controlId="formCity">
            <Form.Label>Program City *</Form.Label>
            <Form.Select aria-label="Default select example" name="City" onChange={handleCityChange} required>
                <option>please select</option>
                <option value="Pune">Pune</option>
                <option value="Solapur">Solapur</option>
            </Form.Select>
        </Form.Group>

        {/* {currentCity === "Pune" &&
            <Form.Group className="mb-3" controlId="formLocation">
                <Form.Label>Program Location *</Form.Label>
                <div>
                    <Form.Check
                        inline
                        label={puneAddress}
                        name="Location"
                        type="radio"
                        value={puneAddress}
                        onChange={handleLocationChange}
                        />
                    <Form.Check
                        inline
                        label="Pune Location2"
                        name="Location"
                        type="radio"
                        value={"Pune location2"}
                        onChange={handleLocationChange}
                        />
                </div>
            </Form.Group>
        }

        {currentCity === "Solapur" &&
            <Form.Group className="mb-3" controlId="formLocation">
                <Form.Label>Program Location *</Form.Label>
                <div>
                    <Form.Check
                        inline
                        label="Solapur Location1"
                        name="Location"
                        type="radio"
                        value={"Solapur location1"}
                        onChange={handleLocationChange}
                    />
                    <Form.Check
                        inline
                        label="Solapur Location2"
                        name="Location"
                        type="radio"
                        value={"Solapur location2"}
                        onChange={handleLocationChange}
                    />
                </div>
            </Form.Group>
        }

        {currentLocation === puneAddress &&
            <Form.Group className="mb-3" controlId="formTime">
                <Form.Label>Batch *</Form.Label>
                <div>
                    <Form.Check
                        inline
                        label="6:30 AM - 9:15 AM"
                        name="Time"
                        type="radio"
                        value={"6:30 AM - 9:15 AM"}
                    />
                    <Form.Check
                        inline
                        label="9:15 AM"
                        name="Time"
                        type="radio"
                        value={"9:15 AM"}
                    />
                </div>
            </Form.Group>
        }

        {currentLocation === "Pune location2" &&
            <Form.Group className="mb-3" controlId="formTime">
                <Form.Label>Batch *</Form.Label>
                <div>
                    <Form.Check
                        inline
                        label="10 AM"
                        name="Time"
                        type="radio"
                        value={"10 AM"}
                    />
                    <Form.Check
                        inline
                        label="5 PM"
                        name="Time"
                        type="radio"
                        value={"5 PM"}
                    />
                </div>
            </Form.Group>
        }

        {currentLocation === "Solapur location1" &&
            <Form.Group className="mb-3" controlId="formTime">
                <Form.Label>Batch *</Form.Label>
                <div>
                    <Form.Check
                        inline
                        label="11 AM"
                        name="Time"
                        type="radio"
                        value={"11 AM"}
                    />
                    <Form.Check
                        inline
                        label="6 PM"
                        name="Time"
                        type="radio"
                        value={"6 PM"}
                    />
                </div>
            </Form.Group>
        }

        {currentLocation === "Solapur location2" &&
            <Form.Group className="mb-3" controlId="formTime">
                <Form.Label>Batch *</Form.Label>
                <div>
                    <Form.Check
                        inline
                        label="12 AM"
                        name="Time"
                        type="radio"
                        value={"12 AM"}
                    />
                    <Form.Check
                        inline
                        label="7 PM"
                        name="Time"
                        type="radio"
                        value={"7 PM"}
                    />
                </div>
            </Form.Group>
        } */}

        <Form.Group className="mb-3" controlId="formName">
            <Form.Label>Name of Participant *</Form.Label>
            <Form.Control type="text" placeholder="enter name" name="Name" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formAge">
            <Form.Label>Age *</Form.Label>
            <Form.Control type="number" placeholder="enter age" name="Age" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGender">
            <Form.Label>Gender *</Form.Label>
            <Form.Select aria-label="please select" name="Gender" required>
                <option value="Female">Female</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formMobile">
            <Form.Label>Mobile Number *</Form.Label>
            <p><i>(preferably of WhatsApp)</i></p>
            <Form.Control type="number" placeholder="enter number" name="Mobile" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email ID</Form.Label>
            <Form.Control type="email" placeholder="enter email" name="Email" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formQualification">
            <Form.Label>Qualification</Form.Label>
            <Form.Control type="text" placeholder="enter qualification" name="Qualification" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formOccupation">
            <Form.Label>Occupation</Form.Label>
            <Form.Control type="text" placeholder="enter occupation" name="Occupation" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="fomAddress">
            <Form.Label>Address *</Form.Label>
            <Form.Control as="textarea" rows={3} name="Address" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formEmergencyNumber">
            <Form.Label>Emergency Contact Number *</Form.Label>
            <Form.Control type="number" placeholder="please enter" name="EmergencyContactNumber" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formEmergencyContactRelation">
            <Form.Label>Emergency Contact Name & Relation *</Form.Label>
            <Form.Control type="text" placeholder="please enter" name="EmergencyContactRelation" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formHowGotToKnow">
            <Form.Label>How did you get to know about this program? *</Form.Label>
            <Form.Select aria-label="please select" name="HowGotToKnow" required>
                <option value="Instagram">Instagram</option>
                <option value="Facebook">Facebook</option>
                <option value="Wordofmouth">Word of Mouth</option>
                <option value="Doctors">Doctors</option>
                <option value="Ads">Ads</option>
                <option value="FrndsFmly">Friends & Family</option>
                <option value="Other">Other</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formYogab4">
            <Form.Label>Please give details of yoga or meditation you have practiced before and how long have you been practicing? (if any)</Form.Label>
            <Form.Control as="textarea" rows={2} name="Yogab4" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formIshayoga">
            <Form.Label>Have you learnt any other Isha Yoga Practices? If yes, please give details:</Form.Label>
            <Form.Control as="textarea" rows={2} name="Ishayoga" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formAilments" required>
            <Form.Label>Please indicate below if you currently or previously have had any physical or mental ailments: *</Form.Label>
            <Form.Check inline label="Any Physical Limitations or Disabilities" type="checkbox" value="Any Physical Limitations or Disabilities" onChange={handleAilmentsChange} />
            <Form.Check inline label="Joint Related Issues" type="checkbox" value="Joint Related Issues" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Ligament Injuries" type="checkbox" value="Ligament Injuries" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Spinal Conditions" type="checkbox" value="Spinal Conditions" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Bowel / Bladder Issues" type="checkbox" value="Bowel / Bladder Issues" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Communicable Disease" type="checkbox" value="Communicable Disease" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Chronic Pain" type="checkbox" value="Chronic Pain" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Glaucoma / Retinal Detachment / Eye Surgery" type="checkbox" value="Glaucoma / Retinal Detachment / Eye Surgery" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Depression / Psychosis" type="checkbox" value="Depression / Psychosis" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Diabetes" type="checkbox" value="Diabetes" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Respiratory Conditions" type="checkbox" value="Respiratory Conditions" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Heart Conditions" type="checkbox" value="Heart Conditions" onChange={handleAilmentsChange}/>
            <Form.Check inline label="High Blood Pressure" type="checkbox" value="High Blood Pressure" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Low Blood Pressure" type="checkbox" value="Low Blood Pressure" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Seizures / Epilepsy" type="checkbox" value="Seizures / Epilepsy" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Stroke" type="checkbox" value="Stroke" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Bleeding Disorders" type="checkbox" value="Bleeding Disorders" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Hernia" type="checkbox" value="Hernia" onChange={handleAilmentsChange}/>
            <Form.Check inline label="Hospitalization for a Psychiatric Condition in the past" type="checkbox" value="Hospitalization for a Psychiatric Condition in the past" onChange={handleAilmentsChange}/>
            <Form.Check inline label="None" type="checkbox" value="None" onChange={handleAilmentsChange}/>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formIfany">
            <Form.Label>If Selected any of the above, please provide additional details:
            </Form.Label>
            <Form.Control as="textarea" rows={2} name="Ifany" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSeriousillness3years">
            <Form.Label>Any serious illness in the last three years? *</Form.Label>
            <Form.Select aria-label="please select" name="Seriousillness3years" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formAnyInjury">
            <Form.Label>Any injury in the last three years? *</Form.Label>
            <Form.Select aria-label="please select" name="AnyInjury" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formSurgery3years">
            <Form.Label>Any surgery in the last three years? *</Form.Label>
            <Form.Select aria-label="please select" name="Surgery3years" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formPsychotherapyTreament">
            <Form.Label>Psychotherapy, Psychological Therapy and Counseling in the last five years? *</Form.Label>
            <Form.Select aria-label="please select" name="PsychotherapyTreament" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formDetailsOfTreatment">
            <Form.Label>If Any of the above is selected as yes, please give details of the nature and duration of the condition and if you are currently undergoing any treatment:
            </Form.Label>
            <Form.Control as="textarea" rows={2} name="DetailsOfTreatment" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formHistoryOfSmoking">
            <Form.Label>History of Smoking? *</Form.Label>
            <Form.Select aria-label="please select" name="HistoryOfSmoking" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>
        </Form.Group><Form.Group className="mb-3" controlId="formHistoryOfDrugs">
            <Form.Label>History of Drugs? *</Form.Label>
            <Form.Select aria-label="please select" name="HistoryOfDrugs" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formHistoryOfAlcohol">
            <Form.Label>History of Alcohol? *</Form.Label>
            <Form.Select aria-label="please select" name="HistoryOfAlcohol" required>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formLastPeriodDate">
            <Form.Label>Last Periods Date: *</Form.Label>
            <Form.Control type="date" placeholder="please enter" name="LastPeriodDate" required />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formOtherinfo">
            <Form.Label>Any other comments or information you would like to specify:
            </Form.Label>
            <Form.Control as="textarea" rows={2} name="Otherinfo" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formConsent" required>
            <Form.Label>Declaration: *</Form.Label>
            <Form.Check
            inline
            label="I hereby willingly undertake to attend this program completely. I take full responsibility for the result and indemnify the organizers against all claims and suits. I will not communicate the contents of the program, either directly or indirectly to anyone else. I understand the participation guidelines and agree to follow them. I hereby declare that the above information is true, accurate and complete to the best of my knowledge."
            name="Consent"
            type="checkbox"
            />
        </Form.Group>
        <Button className={styles.button} variant="success" type="submit">
            Submit
        </Button>
      </Form>
    </Container>
    </div>
  );
};
export default PregnancyForm;