import styles from './UpOff.module.scss';
import svg2 from '../../assets/svS_1a.svg';
import ProgramCarousel from './ProgramCarousel';
// import ControlledCarousel from './c1';

const UpOff = () => {
    return(
        <div className={styles.upofferings}>
            <h1>Upcoming Offerings</h1>
            <img src={svg2} alt='svC' width='300px'></img>
            <div className={styles.mainContent}>
                <ProgramCarousel />
            </div>
        </div>
    )
}

export default UpOff;