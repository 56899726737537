import styles from './Suryashakti.module.scss';
import svg2 from '../../../assets/svS_1a.svg';
import img from '../../../assets/surya_shakti_830x300.jpg';
import Acco from './Accordion';
import { Button } from 'react-bootstrap';

const Suryashakti = () => {
    return(
        <div className={styles.title}>
            <h1>Surya Shakti</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <div className={styles.left}>
                    <img src={img} alt='suryashakti' width='100%'></img>
                    <p>Surya means “Sun,” and Shakti means “Energy”. Surya Shakti is a complete process, which is largely physical in nature. If you do 108 cycles of Surya Shakti, it will make you fit and you will not need any other form of exercise.</p>
                    <p><b>Benefits:</b><br/>
                    Surya Shakti enriches your life in many ways as:<br/>
                    ♦ Brings about physical fitness and overall wellbeing.<br/>
                    ♦ Makes the sinews and ligaments of the body strong.<br/>
                    ♦ Increases mental alertness and focus.<br/>
                    ♦ Creates a basis for one to move into higher states of energy.<br/>
                    ♦ Increases physical strength & stamina.<br/>
                    ♦ Realigns the musculo-skeletal system.<br/>
                    ♦ Increases energy levels.<br/>
                    </p>
                    <Acco />
                </div>
                <div className={styles.right}>
                    <h2>upcoming offerings</h2>
                    <img src={svg2} alt='svC' width='275px' style={{paddingBottom:'50px'}}></img>
                    <div className={styles.card}>
                        <h5>Surya Shakti<hr/></h5>
                        <p><b>Location: </b>Sayuri Hospital, behind DryFruit House, Postal Colony, Shankar Kalat Nagar, Wakad, Pune, Pimpri-Chinchwad, Maharashtra - 411057</p>
                        <p><b>Date: </b>-</p>
                        <p><b>Time: </b>-</p>
                        <Button disabled>Register Now</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Suryashakti;