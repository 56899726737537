import styles from './Suryakriya.module.scss';
import svg2 from '../../../assets/svS_1a.svg';
import img from '../../../assets/surya_kriya_2_830x300.jpg';
import Acco from './Accordion';
import { Button } from 'react-bootstrap';

const Suryakriya = () => {
    return(
        <div className={styles.title}>
            <h1>Surya Kriya</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <div className={styles.left}>
                    <img src={img} alt='suryakriya' width='100%'></img>
                    <p>Traditionally available only to select groups of yogis, Surya Kriya is being offered by Sadhguru as a comprehensive spiritual practice that is ideal for the hectic pace of today’s world. Surya Kriya is a potent 21-step yogic practice of tremendous antiquity, designed as a holistic process for health, wellness and complete inner wellbeing. Surya Kriya is a complete spiritual process by itself.</p>
                    <p><b>Benefits:</b><br/>
                    ♦ Develops mental clarity and focus.<br/>
                    ♦ Remedies weak constitutions.<br/>
                    ♦ Boosts vigor and vitality.<br/>
                    ♦ Balances the body, mind and energies.<br/>
                    ♦ Rejuvenates all the major organ systems including balancing hormonal levels.<br/>
                    ♦ Supports one to become meditative and experience peacefulness and joy.<br/>
                    </p>
                    <Acco />
                </div>
                <div className={styles.right}>
                    <h2>upcoming offerings</h2>
                    <img src={svg2} alt='svC' width='275px' style={{paddingBottom:'50px'}}></img>
                    <div className={styles.card}>
                        <h5>Surya Kriya <hr/></h5>
                        <p><b>Location: </b>Sayuri Hospital, behind DryFruit House, Postal Colony, Shankar Kalat Nagar, Wakad, Pune, Pimpri-Chinchwad, Maharashtra - 411057</p>
                        <p><b>Date: </b>-</p>
                        <p><b>Time: </b>-</p>
                        <Button disabled>Register Now</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Suryakriya;