import Accordion from 'react-bootstrap/Accordion';

const Acco = () => {
  return (
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey="0">
        <Accordion.Header>Eligibility & Guidelines</Accordion.Header>
        <Accordion.Body>
        <span style={{fontWeight:'500'}}>ELIGIBILITY:</span> Women who are pregnant.<br/>
        <span style={{fontWeight:'500'}}>OPEN TO ALL:</span> No prior yoga experience needed.<br/>
        <span style={{fontWeight:'500'}}>ATTENDANCE REQUIREMENTS:</span> Full attendance for all sessions is mandatory.<br/><br/>
        <span style={{fontWeight:'500'}}>PLEASE NOTE:</span> Due to the nature of this practice, certain individuals are currently ineligible to participate:<br/>
        ♦ Those who have undergone major open surgery within the past 6 months.<br/>
        ♦ Those who have had laparoscopic surgery within the last 6 weeks.<br/>
        ♦ For your safety, always consult your doctor and follow medical advice. If you have questions about participation, please reach out to the teacher directly.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Cancellation & Refund Policies</Accordion.Header>
        <Accordion.Body>
        ♦ <span style={{fontWeight:'500'}}>Absences & Lateness:</span> No refunds for missed or late attendance.<br/>
        ♦ <span style={{fontWeight:'500'}}>Re-registration Fee:</span> Participants may re-register for a class within 6 months at a 15% re-registration fee.<br/>
        ♦ <span style={{fontWeight:'500'}}>Medical Withdrawal:</span> Participants who withdraw due to medical reasons with proof may rejoin within 6 months for a 15% re-registration fee. Alternatively, an approved contact may take their place within this period.<br/>
        ♦ <span style={{fontWeight:'500'}}>Teacher Cancellations:</span> If a class is canceled by the teacher, participants can choose to either:<br/>
        a. Join the next program free with priority.<br/>
        b. Receive a full refund, with transaction fees covered by the teacher.<br/>
        ♦ <span style={{fontWeight:'500'}}>Teacher Absence:</span> If a teacher is out of town, participants with valid reasons to drop out can join the next available class within 2-3 months. If no class is available in that timeframe, a full refund will be issued. If another teacher is conducting a class in the same location, fees will be transferred to them, requiring teacher cooperation.<br/>
        ♦ <span style={{fontWeight:'500'}}>Relocation:</span> Participants relocating to another city are entitled to a full refund if the teacher cancels the class.<br/>
        ♦ <span style={{fontWeight:'500'}}>Medical Ineligibility:</span> If a participant is found ineligible before the class due to a medical condition, a full refund minus transaction fees will be provided. No refunds are issued if ineligibility is discovered during the class.<br/>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default Acco;