import styles from './Yogasanas.module.scss';
import svg2 from '../../../assets/svS_1a.svg';
import img from '../../../assets/yogasanas_830x300.jpg';
import Acco from './Accordion';
import { Button } from 'react-bootstrap';

const Yogasanas = () => {
    return(
        <div className={styles.title}>
            <h1>Yogasanas</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <div className={styles.left}>
                    <img src={img} alt='yogasanas' width='100%'></img>
                    <p>Yogasanas are physical postures through which one can transform the body and the mind into a possibility for ultimate wellbeing. Offered as a set of 21 powerful postures, this is a way of aligning the inner system and adjusting it to the celestial geometry. Yogasanas are not exercises, but rather very subtle processes to manipulate one’s energy to naturally achieve a chemistry of health, joy, blissfulness, and above all balance.</p>
                    <p><b>Benefits:</b><br/>
                    ♦ Relief of chronic health conditions.<br/>
                    ♦ Evolution of body and mind towards a higher possibility.<br/>
                    ♦ Stabilization of the body, mind and energy system.<br/>
                    ♦ Deceleration of the aging process.<br/>
                    </p>
                    <Acco />
                </div>
                <div className={styles.right}>
                    <h2>upcoming offerings</h2>
                    <img src={svg2} alt='svC' width='275px' style={{paddingBottom:'50px'}}></img>
                    <div className={styles.card}>
                        <h5>Yogasanas<hr/></h5>
                        <p><b>Location: </b>Sayuri Hospital, behind DryFruit House, Postal Colony, Shankar Kalat Nagar, Wakad, Pune, Pimpri-Chinchwad, Maharashtra - 411057</p>
                        <p><b>Date: </b>-</p>
                        <p><b>Time: </b>-</p>
                        <Button disabled>Register Now</Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Yogasanas;