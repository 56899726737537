import styles from './Contact.module.scss';
import svg2 from '../../assets/svS_1a.svg';

import { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'react-bootstrap';

const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
        .sendForm('', '', form.current, {
            publicKey: '',
        })
        .then(
            (result) => {
            toast.success('Sent Successfully!', result.text);
            e.target.reset();
            },
            (error) => {
            toast.error('Failed to Send...', error.text);
            },
        );
    };

    return(
        <div className={styles.contact}>
            <h1>Contact</h1>
            <img src={svg2} alt='svC' width='300px' style={{paddingBottom:'50px'}}></img>
            <div className={styles.mainContent}>
                <form ref={form} onSubmit={sendEmail} className={styles.inputform} >
                    <div>
                        <input type="text" placeholder="Your Name" name='user_name' required/>
                    </div>
                    <div>
                        <input type="text" placeholder="Mobile number" name='user_mobile' required/>
                    </div>
                    <div>
                        <input type="email" placeholder="Your Email" name='user_email' required/>
                    </div>
                    <div>
                        <textarea placeholder="Your Message..." name='message' required/>
                    </div>
                    {/* <div> */}
                        {/* <button type='submit' value='Send' className={styles.button}>Send</button> */}
                        {/* <input type="submit" value="Send" className={styles.button}/> */}
                    {/* </div> */}
                    <Button type='submit' value='Send' className={styles.button}>SEND</Button>
                </form>
            </div>
        </div>
    )
}

export default Contact;