const carouselData = [
  {
    id: 1,
    title: "Angamardana",
    location: "Location: Pune",
    date: "23rd Nov - 25th Nov 2024",
    time: "06:30 AM - 09:15 AM",
    registrationLink: "/angamardana",
    startDate: "2024-11-23",
    endDate: "2024-11-26"
  },
  {
    id: 2,
    title: "Yoga Workshop for Pregnant Women",
    location: "Location: Pune",
    date: "9th Nov - 11th Nov 2024",
    time: "07:00 AM - 08:45 AM",
    registrationLink: "#",
    startDate: "2024-11-02",
    endDate: "2024-11-21"
  },
  {
    id: 3,
    title: "Yoga Bros",
    location: "Location: Pune",
    date: "9th Nov - 11th Nov 2024",
    time: "07:00 AM - 08:45 AM",
    registrationLink: "#",
    startDate: "2024-11-01",
    endDate: "2024-11-02"
  },
  {
    id: 4,
    title: "Workshop for All",
    location: "Location: Pune",
    date: "9th Nov - 11th Nov 2024",
    time: "07:00 AM - 08:45 AM",
    registrationLink: "#",
    startDate: "2024-11-01",
    endDate: "2024-11-02"
  }
];

export default carouselData;